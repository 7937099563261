
import { defineComponent } from 'vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SelectInput from '@/components/atom/SelectInput.vue'
import Header from '@/components/organisms/Header.vue'
import FileInput from '@/components/atom/FileInput.vue'
import { documentTypes } from '@shared/*'
import { Form } from 'vee-validate'
import * as yup from 'yup'
import authApi from '@/api/Authenticated'
import { useRouter, useRoute } from 'vue-router'
import commonString from '@/utils/commonStrings'
export default defineComponent({
  name: 'addDocument',
  components: {
    CustomButton,
    SelectInput,
    Header,
    FileInput,
    Form
  },
  setup() {
    const label = 'Select value'
    const router = useRouter()
    const route = useRoute()
    const items = [
      {
        id: 1,
        label: commonString.NDA,
        value: 'NDA'
      },
      {
        id: 2,
        label: commonString.MSA,
        value: 'MSA'
      }
    ]

    const initialValues: any = {
      documentType: route.query.type ?? '',
      'files[]': ''
    }

    const schema = yup.object().shape({
      documentType: yup.string().required().label('Docuemnt type'),
      files: yup.array().required().label('File')
    })

    async function submit(values: documentTypes.DocumentBodyFields & { files: any; documentType: any }) {
      const formData = new FormData()
      formData.append('documentType', values.documentType)
      formData.append('files', values.files[0])
      const res = await authApi.addNewDocument(formData)
      if (res) {
        router.push({ name: 'Documents' })
      }
    }

    return { label, items, submit, schema, initialValues }
  }
})
